import React, { useEffect, useMemo, useState } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { useParams } from "react-router-dom";
import { getSeriesMatchID } from "./common/helper";
import { play11, matchObj } from "./common/ShimmerData";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { getAssetUrl } from "../../../../../helpers/general";

export const playerRole = {
  bat: "Batter",
  all: "All Rounder",
  wk: "Wicket Keeper",
  bowl: "Bowler",
};

const iconSUB = getAssetUrl("sub.svg");
const iconIMP = getAssetUrl("imp.svg");

const Playing11 = ({ isSquad }) => {
  const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
  const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/playing11`, {
    refreshInterval,
  });
  const LiveMatchStatus = data && data?.match?.status === "Live";
  useEffect(() => {
    if (LiveMatchStatus) {
      setRefreshInterval(5000);
    } else {
      setRefreshInterval(0);
    }
  }, [LiveMatchStatus]);
  const matchdata = isLoading ? matchObj : data?.match;
  const playing11Data = isLoading ? play11?.info?.playing11 : data?.info?.playing11;
  const memoizedValues = useMemo(() => {
    const teamNamesById = {
      [matchdata?.teama?.team_id]: matchdata?.teama?.short_name || '',
      [matchdata?.teamb?.team_id]: matchdata?.teamb?.short_name || '',
    };
    const teamLogoById = {
      [matchdata?.teama?.team_id]: matchdata?.teama?.logo || '',
      [matchdata?.teamb?.team_id]: matchdata?.teamb?.logo || '',
    };
    const getPlayers = (team) => ({
      playing11: team?.squads?.filter((obj) => obj.playing11 === "true"),
      bench: team?.squads?.filter((obj) => obj.playing11 === "false"),
      name: teamNamesById[team?.team_id] || '',
      logo: teamLogoById[team?.team_id]
    });

    const teama = getPlayers(playing11Data?.teama);
    const teamb = getPlayers(playing11Data?.teamb);

    return { [playing11Data?.teama?.team_id]: teama, [playing11Data?.teamb?.team_id]: teamb };
  }, [playing11Data, matchdata]);

  const teamPlaying11Length = memoizedValues[matchdata?.teama?.team_id]?.playing11?.length > 0 || memoizedValues[matchdata?.teamb?.team_id]?.playing11?.length > 0;

  if (error) {
    return (
      <h2></h2>
    );
  }
  const shimmerClassName = matchdata?.isShimmer ? "bg-gray-300 loading text-gray-300" : ""
  return (
    <>
      <div className="relative">
        <div className={`border border-[#D8E7FF] rounded-2xl md:bg-[#F9FBFF] ${!(!teamPlaying11Length && !isExpanded) ? '' : 'h-[165px] md:h-[200px]'}`}>
          <div className="text-nowrap rounded-t-lg p-4 py-3 md:text-[18px] text-[14px]">
            <div className={`${shimmerClassName} md:min-h-[27px] min-h-[22px] min-w-24 grid grid-cols-2`}>
              <div className="flex md:gap-4 gap-1.5 items-center border-r border-[#87B6FF]">
                <img src={memoizedValues[matchdata?.teama?.team_id]?.logo} alt="" className="h-[22px] md:h-[34px] w-[22px] md:w-[34px]" />
                <div className="font-bold text-[9px] md:text-[28px]">
                  {memoizedValues[matchdata?.teama?.team_id]?.name?.toUpperCase()}
                </div>
              </div>
              <div className="flex justify-end md:gap-4 gap-1.5 items-center">
                <div className="font-bold text-[9px] md:text-[28px]">
                  {memoizedValues[matchdata?.teamb?.team_id]?.name?.toUpperCase()}
                </div>
                <img src={memoizedValues[matchdata?.teamb?.team_id]?.logo} alt="" className="h-[22px] md:h-[34px] w-[22px] md:w-[34px]" />
              </div>
            </div>
          </div>
          <p className="font-medium md:font-semibold md:text-[18px] text-[8px] md:leading-5 text-center border-y border-[#D8E7FF] bg-[#E8F1FF] py-1" id="playing_11">Playing XI</p>
          <div className={`md:px-4 overflow-hidden transition-max-height duration-300 ease-in-out ${isExpanded ? "" : "md:max-h-[300px] max-h-[200px]"} grid grid-cols-2`}>
            {renderplayerType([matchdata?.teama?.team_id, matchdata?.teamb?.team_id], "playing11", memoizedValues, shimmerClassName)}
          </div>
          {isExpanded && (
            <>
              <p className="font-medium md:font-semibold md:text-[18px] text-[8px] md:leading-5 text-center border-y border-[#D8E7FF] bg-[#E8F1FF] py-1">Bench</p>
              <div className={`md:px-4 overflow-hidden transition-max-height duration-300 ease-in-out grid grid-cols-2`}>
                {renderplayerType([matchdata?.teama?.team_id, matchdata?.teamb?.team_id], "bench", memoizedValues, shimmerClassName)}
              </div>
            </>
          )}
        </div>
        {!isExpanded && (
          <div className="absolute bottom-0 left-0 right-0 flex rounded-b-2xl items-end justify-center w-full pb-4 h-[150px] bg-gradient-to-t from-white to-transparent">
            <button
              onClick={() => setIsExpanded(true)}
              className="text-[#3A32D1] font-semibold text-xs bg-white rounded-full md:py-3 py-2 md:px-8 px-4 shadow-lg flex items-center gap-2"
            >
              Full Playing 11
              <MdOutlineKeyboardArrowDown />
            </button>
          </div>
        )}
        {isExpanded && (
          <div className="py-2 md:pb-0 md:pt-4">
            <button
              onClick={() => {
                setIsExpanded(false)
                scrollSmooth("playing_11");
              }}
              className="text-[#3A32D1] font-semibold text-xs bg-white rounded-full md:py-3 py-2 md:px-8 mx-auto px-4 shadow-lg flex items-center gap-2"
            >
              Close
              <MdOutlineKeyboardArrowUp />
            </button>
          </div>
        )}
      </div>
    </>
  );
};
const renderplayerType = (ary, type, memoizedValues, shimmerClassName) => {
  // [matchdata?.teama?.team_id, matchdata?.teamb?.team_id]
  return (
    ary.map((teamId, i) => (
      <div key={teamId}>
        <div className={`${i === 0 ? "" : "border-l md:border-[#D8E7FF] text-right"}`}>
          {memoizedValues[teamId]?.[type]?.length > 0
            ? renderPlayers(memoizedValues[teamId]?.[type], shimmerClassName, i)
            : <div className="p-4 md:text-sm text-xs">{type === "playing11" ? 'Playing 11 Yet to Announce' : "No players on bench"}</div>
          }
        </div>
      </div>
    ))
  )
}
const renderPlayers = (players, shimmerClassName, index) => (
  <>
    {(players || []).map((player, i) => (
      <div className={`${index ? "pr-3 md:pr-0 place-items-end" : "md:pl-0 pl-3 place-items-start"} ${i === 0 ? "border-t-transparent" : ""} grid grid-cols-7 lg:grid-cols-12 lg:gap-0 md:gap-2 items-center border border-[#DAE6F8] border-b-transparent border-x-transparent py-3 md:py-4 md:p-1`} key={i}>
        <div className={`${index ? "order-2" : "order-1"} md:col-span-2 col-span-1 rounded-full bg-[#c6c6c6] md:text-lg font-semibold text-white md:h-[50px] h-6 md:w-[50px] w-6 text-sm flex justify-center items-center ${shimmerClassName}`}>
          {player.name[0]}
        </div>
        <div className={`${index ? "order-1 mr-2" : "ml-2 order-2"} lg:col-span-10 col-span-6`}>
          <div className="md:text-[14px] md:leading-5 leading-[13px] text-[12px] flex items-center justify-between">
            <p className={`${shimmerClassName} ${index ? "order-2" : "order-1"} min-h-[13px] md:min-h-5 md:max-w-full ${player.out === 'true' || player.substitute === 'true' ? "max-w-[100px]" : ""} live-match`}>
              {player.name} {player.role_str}
            </p>
            <div className={`${index ? "order-1 mr-1" : "order-2 ml-1"}`}>
              {player.out === 'true' &&
                <p className="">
                  <img src={iconSUB} className="h-[12px] md:h-[16px]" />
                </p>
              }
              {player.substitute === 'true' &&
                <p className="">
                  <img src={iconIMP} className="h-[12px] md:h-[16px]" />
                </p>
              }
            </div>
          </div>
          <p className={`${shimmerClassName} md:min-h-[14px] min-h-[10px] md:text-[14px] text-[8px] text-[#929292] mt-0.5 md:mt-0 `}>
            {playerRole[player.role]}
          </p>
        </div>
      </div>
    ))}
  </>
);
export default Playing11;
